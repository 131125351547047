import { Button, Card, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { QuestionsContext, ResultsContext, ScoreContext } from "../utils/Context"

export default function Results ({nbq}) {

    const { score } = useContext(ScoreContext);
    const { questions } = useContext(QuestionsContext);
    const { yourResults } = useContext(ResultsContext);

    var idQuest = -1;

    function convert (string) {
        var returnedString = string;
        returnedString.replace(/&#(?:x([\da-f]+)|(\d+));/ig, function (_, hex, dec) {
          return String.fromCharCode(dec || +('0x' + hex));
        })
        returnedString = returnedString.replace(/&amp;/g, '&');
        returnedString = returnedString.replace(/&quot;/g, '"');
        returnedString = returnedString.replace(/&#039;/g, "'");
        returnedString = returnedString.replace(/&euml;/g, 'ë');
        returnedString = returnedString.replace(/&eacute;/g, 'é')

        return returnedString;
    }
    
    return(
        <>
            <Grid container spacing={2} marginTop={3} marginBottom={5}>
                <Grid item xs={2} />
                <Grid item xs={8} textAlign='center'>
                    <Typography variant="h4">
                        Here's your score : {score + '/' + nbq} 
                        {(score) < nbq/2 ? 
                            " I'm sure you can beat this ! Retry !" : 
                        (((score) > nbq/2) & ((score) < (75/100)*nbq)) ?
                              <strong> Not bad !</strong> : 
                        (((score) < (75/100)*nbq) & nbq-score >= 0) ?
                            " Almost perfect ! Really well done" :
                        nbq - score === 0 ? " This is perfect. No words !" : ''
                        }
                    </Typography>
                </Grid>
                <Grid item xs={2} />
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={2} />
                <Grid item xs={8} textAlign='center'>
                    <Stack gap={2} textAlign='left'>
                        {   
                            questions.map((q) => {
                                idQuest++;
                                return(
                                    <Card>
                                        <CardContent raised>
                                            <Typography variant="overline" fontSize={15}>
                                                {idQuest + 1}. {convert(q.question)}
                                            </Typography>
                                            <p style={{marginLeft: '30px'}}><Typography variant="caption" fontSize={15}>Your answer : <span style={convert(q.correct_answer) === convert(yourResults[idQuest]) ? {color: 'green'} : {color: 'red'} }>{yourResults[idQuest] === '' ? 'NO ANSWER' : yourResults[idQuest]}</span></Typography></p>
                                            <p style={{marginLeft: '30px'}}><Typography variant="caption" fontSize={15}>Correct answer : <span style={{color: 'green'}}>{convert(q.correct_answer)}</span></Typography></p>
                                        </CardContent>
                                    </Card>
                                )
                            })
                        }
                    </Stack>
                </Grid>
                <Grid item xs={2} />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={2} />
                <Grid item xs={8} textAlign='center' marginTop={5}>
                    <Divider>
                        <Button as={Link} to='/questions' size="large"> Try again ? (same options) </Button> - <Button as={Link} to='/' size="large">Try with others options</Button>
                    </Divider>
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </>
    )
}