import { useState } from "react";
import { createContext } from "react";

export const ScoreContext = createContext();

export const ScoreProvider = ({children}) => {
    const [score, setScore] = useState(0);
    
    const incrScore = () => {
        setScore(score + 1);
    }

    const decScore = () => {
        if(score > 0)
            setScore(score - 1);   
    }

    return(
        <ScoreContext.Provider value={{score, incrScore, decScore}}>
            {children}
        </ScoreContext.Provider>
    )
}

export const ResultsContext = createContext();

export const ResultsProvider = ({children}) => {
    const [yourResults, setYourResults] = useState([]);

    const handleResults = (e, idQuest) => {
        yourResults[idQuest] = e.target.id;
    }

    const resetResults = (nbq) => {
        setYourResults([]);

        for(let i = 0; i < nbq; i++){
            setYourResults(oldResults => [...oldResults, '']);
        }
    }

    return(
        <ResultsContext.Provider value={{yourResults, handleResults, resetResults}}>
            {children}
        </ResultsContext.Provider>
    )
}

export const QuestionsContext = createContext();

export const QuestionsProvider = ({children}) => {

    const [questions, setQuestions] = useState([]);

    const fetchQuestions = (url) => {
        fetch(url)
            .then((res) => res.json())
            .then((res) => setQuestions(res.results));
    }

    return(
        <QuestionsContext.Provider value={{questions, fetchQuestions}}>
            {children}
        </QuestionsContext.Provider>
    )
}