import './Home.css'
import { Grid, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { Link } from "react-router-dom";

export default function Home({nbq, typquest, onChange, onChangeTypeQuest, difficulty, onChangeDifficulty, nbSeconds, onChangeSeconds}) {
    return(
        <>
            <Grid container spacing={2}>
                <Grid item xs={2} />
                <Grid item xs={8} className='text-center'>
                    <Typography variant="h1" component="div">
                        Online Quizz
                    </Typography>
                    <Typography variant="subtitle1">
                        Lack of memory ? You want to increase your knowledge ? This quizz is for you !
                    </Typography> 
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={2} />
                <Grid item xs={8} className='text-center' marginTop='30px'>
                    <Typography variant="subtitle1">
                        Choose the number of question, select which difficulty you want to face. "True or False" or Multiple questions ?
                    </Typography> 
                    <Typography variant="subtitle1">
                        Select what you want and give some training to you knowledge !
                    </Typography> 
                </Grid>
                <Grid item xs={2} />
            </Grid>
            <Grid container spacing={2} marginTop='100px' marginBottom='auto'>
                <Grid item xs={3} />
                <Grid item xs={2} marginLeft='20px'>
                    <TextField
                        value={nbq}
                        onChange={onChange}
                        id="standard-required"
                        label="Choose the number of questions (50 max)"
                        defaultValue="10"
                        type='number'
                        fullWidth
                    />  
                </Grid>
                <Grid item xs={2} >
                    <FormControl fullWidth>
                        <InputLabel id="label-typequest">Question type</InputLabel>
                        <Select
                            labelId="label-typequest"
                            value={typquest}
                            onChange={onChangeTypeQuest}
                            label='Question type'
                            fullWidth
                        >
                            <MenuItem value='truefalse'>True or false choice</MenuItem>
                            <MenuItem value='multiple'>Multiple choices</MenuItem>
                            <MenuItem value='random'>Random</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="label-diff">Difficulty</InputLabel>
                        <Select
                            labelId="label-diff"
                            value={difficulty}
                            label='Difficulty'
                            onChange={onChangeDifficulty}
                            fullWidth
                        >
                            <MenuItem value='easy'>Easy</MenuItem>
                            <MenuItem value='medium'>Medium</MenuItem>
                            <MenuItem value='hard'>Hard</MenuItem>
                            <MenuItem value='random'>Random</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={12} marginLeft='20px' textAlign='center'>
                    <Button as={Link} to='/questions' variant="outlined" style={{marginTop: '100px'}} size='large' id="button-go-quizz">
                        Begin the quizz ! 
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}