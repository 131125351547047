import { Badge, Radio, Card, CardContent, Typography, CardActions, RadioGroup, FormControl, FormControlLabel } from "@mui/material"
import { useContext } from "react";
import { useState } from "react"
import { ScoreContext } from "../utils/Context";
import { ResultsContext } from '../utils/Context';
import './Question.css'

export default function Question ({q, tempRand, idQuest}) {

    const [alreadyAnsweredCorrect, toggleAlreadyAnsweredCorrect] = useState(false);
    const [alreadyAnsweredIncorrect, toggleAlreadyAnsweredIncorrect] = useState(false);

    const { incrScore, decScore } = useContext(ScoreContext);
    const { handleResults } = useContext(ResultsContext);

    const handleClick = (e) => {

        handleResults(e, idQuest);

        if(!alreadyAnsweredCorrect & !alreadyAnsweredIncorrect){
            if(e.target.value === 'correct'){
                toggleAlreadyAnsweredCorrect(true);
                toggleAlreadyAnsweredIncorrect(false);
                incrScore();
            } else if(String(e.target.value).includes('incorrect')){
                toggleAlreadyAnsweredIncorrect(true);
                toggleAlreadyAnsweredCorrect(false);
            }

            return
        }

        if(e.target.value === 'correct'){
            if(!alreadyAnsweredCorrect & alreadyAnsweredIncorrect){
                toggleAlreadyAnsweredCorrect(true);
                toggleAlreadyAnsweredIncorrect(false);
                incrScore();
            }
        } else if(String(e.target.value).includes('incorrect')) {
            if(alreadyAnsweredCorrect & !alreadyAnsweredIncorrect){
                toggleAlreadyAnsweredCorrect(false);
                toggleAlreadyAnsweredIncorrect(true);
                decScore();
            }
        }
    }

    function convert (string) {
        var returnedString = string;
        returnedString.replace(/&#(?:x([\da-f]+)|(\d+));/ig, function (_, hex, dec) {
          return String.fromCharCode(dec || +('0x' + hex));
        })
        returnedString = returnedString.replace(/&amp;/g, '&');
        returnedString = returnedString.replace(/&quot;/g, '"');
        returnedString = returnedString.replace(/&#039;/g, "'");
        returnedString = returnedString.replace(/&euml;/g, 'ë');
        returnedString = returnedString.replace(/&eacute;/g, 'é');

        return returnedString;
    }

    const randomButtonsPlacement = (typeQuest) => {
        switch(typeQuest){
            case 'multiple':
                switch(tempRand){
                    case 0:
                        return(
                            <CardActions>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        row
                                    >
                                        <FormControlLabel value='correct'    control={<Radio id={convert(q.correct_answer)} />}       label={convert(q.correct_answer)}       onClick={handleClick} />
                                        <FormControlLabel value='incorrect1' control={<Radio id={convert(q.incorrect_answers[0])} />} label={convert(q.incorrect_answers[0])} onClick={handleClick}/>
                                        <FormControlLabel value='incorrect2' control={<Radio id={convert(q.incorrect_answers[1])} />} label={convert(q.incorrect_answers[1])} onClick={handleClick}/>
                                        <FormControlLabel value='incorrect3' control={<Radio id={convert(q.incorrect_answers[2])} />} label={convert(q.incorrect_answers[2])} onClick={handleClick}/>
                                    </RadioGroup>
                                </FormControl>
                            </CardActions>
                        )
                    case 1:
                        return(
                            <CardActions>
                                <FormControl required>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        row
                                    >
                                        <FormControlLabel value='incorrect1' control={<Radio id={convert(q.incorrect_answers[0])} />} label={convert(q.incorrect_answers[0])} onClick={handleClick}/>
                                        <FormControlLabel value='correct' control={<Radio id={convert(q.correct_answer)} />} label={convert(q.correct_answer)} onClick={handleClick}/>
                                        <FormControlLabel value='incorrect2' control={<Radio id={convert(q.incorrect_answers[1])} />} label={convert(q.incorrect_answers[1])} onClick={handleClick}/>
                                        <FormControlLabel value='incorrect3' control={<Radio id={convert(q.incorrect_answers[2])} />} label={convert(q.incorrect_answers[2])} onClick={handleClick}/>
                                    </RadioGroup>
                                </FormControl>
                            </CardActions>
                        )
                    case 2:
                        return(
                            <CardActions>
                                <FormControl required>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        row
                                    >
                                        <FormControlLabel value='incorrect1' control={<Radio id={convert(q.incorrect_answers[0])} />} label={convert(q.incorrect_answers[0])} onClick={handleClick}/>
                                        <FormControlLabel value='incorrect2' control={<Radio id={convert(q.incorrect_answers[1])} />} label={convert(q.incorrect_answers[1])} onClick={handleClick}/>
                                        <FormControlLabel value='correct' control={<Radio id={convert(q.correct_answer)} />} label={convert(q.correct_answer)} onClick={handleClick}/>
                                        <FormControlLabel value='incorrect3' control={<Radio id={convert(q.incorrect_answers[2])} />} label={convert(q.incorrect_answers[2])} onClick={handleClick}/>
                                    </RadioGroup>
                                </FormControl>
                            </CardActions>
                        )
                    case 3:
                        return(
                            <CardActions>
                                <FormControl required>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        row
                                    >
                                        <FormControlLabel value='incorrect1' control={<Radio id={convert(q.incorrect_answers[0])} />} label={convert(q.incorrect_answers[0])} onClick={handleClick}/>
                                        <FormControlLabel value='incorrect2' control={<Radio id={convert(q.incorrect_answers[1])} />} label={convert(q.incorrect_answers[1])} onClick={handleClick}/>
                                        <FormControlLabel value='incorrect3' control={<Radio id={convert(q.incorrect_answers[2])} />} label={convert(q.incorrect_answers[2])} onClick={handleClick}/>
                                        <FormControlLabel value='correct' control={<Radio id={convert(q.correct_answer)} />} label={convert(q.correct_answer)} onClick={handleClick}/>
                                    </RadioGroup>
                                </FormControl>
                            </CardActions>
                        )
                    default: 
                        break;
                }
                break;
            case 'boolean':
                switch(convert(q.correct_answer)){
                    case 'True':
                        return(
                            <CardActions>
                                <FormControl required>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        row
                                    >
                                        <FormControlLabel value='correct' control={<Radio id={convert(q.correct_answer)} />} label={convert(q.correct_answer)} onClick={handleClick}/>
                                        <FormControlLabel value='incorrect' control={<Radio id={convert(q.incorrect_answers[0])} />} label={convert(q.incorrect_answers[0])} onClick={handleClick}/>
                                    </RadioGroup>
                                </FormControl>
                            </CardActions>
                        )
                    case 'False':
                        return(
                            <CardActions>
                                <FormControl required>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        row
                                    >
                                        <FormControlLabel value='incorrect' control={<Radio id={convert(q.incorrect_answers[0])} />} label={convert(q.incorrect_answers[0])} onClick={handleClick}/>
                                        <FormControlLabel value='correct' control={<Radio id={convert(q.correct_answer)} />} label={convert(q.correct_answer)} onClick={handleClick}/>
                                    </RadioGroup>
                                </FormControl>
                            </CardActions>
                        )
                }
            default:
                break;
        }
    }

    return(
        <div>
            <Badge 
                badgeContent={<Typography variant="overline">{q.difficulty}</Typography>} 
                color={q.difficulty === 'easy' ? 'success' : q.difficulty === 'medium' ? 'warning' : q.difficulty === 'hard' ? 'error' : null} 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Card style={{backgroundColor:'#dad7cd', width: '1000px'}} raised>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" fontStyle='italic'>
                            {q.category} | {q.type === 'multiple' ? 'Multiple choices question' : 'True or false ?'}
                        </Typography>
                        <Typography variant="h6" component="div" >
                            <span id='card-title'>{convert(q.question)}</span>
                            
                        </Typography>
                        {
                            randomButtonsPlacement(q.type)
                        }
                    </CardContent>
                </Card>
            </Badge> 
        </div> 
    )
}