import './App.css';
import Button from '@mui/material/Button';
import Navigation from './common/Navigation';
import { useEffect, useState } from 'react';
import Questions from './pages/Questions';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import { QuestionsProvider, ResultsProvider, ScoreProvider, TimerProvider } from './utils/Context';
import Results from './pages/Results';


function App() {

  const [nbQuestions, setNbQuestions] = useState(10);
  const [typeQuest, setTypeQuest] = useState("");
  const [difficulty, setDifficulty] = useState("");

  const handleChangeNbQuest = (e) => {
    setNbQuestions(e.target.value);
  }

  const handleTypQuest = (e) => {
    setTypeQuest(e.target.value);
  }

  const handleDifficulty = (e) => {
    setDifficulty(e.target.value);
  }

  return(
    <>
      <QuestionsProvider>
        <ScoreProvider>
          <ResultsProvider>
              <Routes>
                <Route path='/' element={<Home nbq={nbQuestions} typquest={typeQuest} onChange={handleChangeNbQuest} onChangeTypeQuest={handleTypQuest} difficulty={difficulty} onChangeDifficulty={handleDifficulty}/>} />
                <Route path='/questions' element={<Questions nbq={nbQuestions} typeQuest={typeQuest} difficulty={difficulty} />} />
                <Route path='/results' element={<Results nbq={nbQuestions} />} />
              </Routes>
          </ResultsProvider>
        </ScoreProvider>
      </QuestionsProvider>
    </>
  )
}

export default App;
