import React from "react"
import { Button, FormControl, Grid, Stack, Typography } from "@mui/material"
import { useEffect, useState, useContext } from "react"
import Question from "./Question"
import { Link } from "react-router-dom"
import { QuestionsContext, ResultsContext, TimerContext } from "../utils/Context"

export default function Questions({nbq, typeQuest, difficulty}){ 

    const [loading, toggleLoading] = useState(true);

    var idQuest = -1;
    var tempRand = 0;

    const { questions, fetchQuestions } = useContext(QuestionsContext);
    const { resetResults } = useContext(ResultsContext);

    var time = 3;

    useEffect(() => {
        
        const url = 'https://opentdb.com/api.php?amount=' + nbq + 
            (typeQuest === 'truefalse' ? '&type=boolean' : typeQuest === 'multiple' ? '&type=multiple' : '') +
            (difficulty === 'easy' ? '&difficulty=easy' : difficulty === 'medium' ? '&difficulty=medium' : difficulty === 'hard' ? '&difficulty=hard' : '')
        
        fetchQuestions(url);
        resetResults(nbq);

        toggleLoading(false)
    }, [])

    if(loading)
        return(
            <p> Questions are loadings ... Get prepared !</p>
        )
    else
        return(
            <Grid container spacing={2} alignContent='center'>
                <Grid item xs={2} />
                <Grid item xs={8} textAlign='center' marginTop={3} marginBottom={2}>
                    <Typography variant="h3" component="div">
                        Let's Go ! Finish the quizz in the less time you can !
                    </Typography>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={12} >
                    <Grid container spacing={3} textAlign='left'>
                        {
                            questions.map((q) => {
                                if(q.type === 'boolean')
                                    tempRand = Math.floor(Math.random() * 2)
                                if(q.type === 'multiple')
                                    tempRand = Math.floor(Math.random() * 4)

                                idQuest += 1;

                                return(
                                    <>
                                        <Grid item xs={2} />
                                        <Grid item xs={8} textAlign='center' >
                                            <Question key={idQuest} q={q} tempRand={tempRand} idQuest={idQuest} />
                                        </Grid>
                                        <Grid item xs={2} />
                                    </>
                                )
                            })
                        }
                    </Grid>
                    </Grid>
                <Grid item xs={1} />
                <Grid item xs={10} textAlign='center' marginTop={10} marginBottom={10}>
                    <Button as={Link} to='/results' variant="outlined" color="success" size="large">
                        I've finished !
                    </Button>
                </Grid>
                <Grid item xs={1} />
                
            </Grid>
        )
}